import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import './css/home.css';
function Home() {
    const navigate = useNavigate(); 
    return (
        <div className="home-container">
            <div className="home-content">
                <h1>Welcome to Infinia</h1>
                <p>Gotta start somewhere - why not here?</p>
                <button className="button home-button" onClick={() => navigate('/paiter')}>
                    Start Chatting
                </button>
            </div>
        </div>
    );
}

export default Home; 