import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../App.css';
import './css/paiter.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;
const typingSound = new Audio('/assets/sounds/typing.wav');

function Paiter() {

  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mood, setMood] = useState('calm');
  const [chatHistory, setChatHistory] = useState([]);
  // const [selectedChatgptModel] = useState('gpt-3.5-turbo');
  const [selectedChatgptModel] = useState('gpt-4');
  const [apiKey] = useState(process.env.REACT_APP_OPENAI_API_KEY);
  const chatEndRef = useRef(null);
  const [pdfText, setPdfText] = useState('');
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);
  const [uploadMessage, setUploadMessage] = useState('');

  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false);
  const [currentResponse, setCurrentResponse] = useState('');
  const [isResponseComplete, setIsResponseComplete] = useState(false);
  const [showFullHistory, setShowFullHistory] = useState(false);
  const [randomMessagesEnabled, setRandomMessagesEnabled] = useState(false);

  const [suggestions, setSuggestions] = useState([
    "What's the summary of the document?",
    "List the key points.",
    "Explain the main idea.",
    "What are the conclusions?",
    "Any important dates or figures?"
  ]);

  const instructionprompt = `
    You are an AI designed to respond with human-like emotions. 
    When a mood is selected, embody that mood in your responses. 
    Avoid any disclaimers about not experiencing emotions. 
    Focus on providing responses that align with the selected mood.
  `;

  const scrollableContainerRef = useRef(null);

  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const playMoodMessage = async (isRandomMessagesEnabled) => {

    if (!isRandomMessagesEnabled) return;

    const moodPrompt = `Please respond with a message that clearly reflects a ${mood} mood.`;
    const apiEndpoint = 'https://api.openai.com/v1/chat/completions';
    const headers = {
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json'
    };

    const data = {
      model: selectedChatgptModel,
      messages: [
        {
          role: 'system',
          content: `${instructionprompt} Respond with a message that clearly reflects a ${mood} mood.`
        },
        ...chatHistory.slice(-1),
        { role: 'user', content: moodPrompt }
      ],
      stream: true
    };

    try {
      setCurrentResponse('');
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';

      playSound('/assets/sounds/game-1.wav');

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonString = line.slice(6);
            if (jsonString === '[DONE]') continue;

            try {
              const jsonData = JSON.parse(jsonString);
              const content = jsonData.choices[0]?.delta?.content || '';
              if (content) {
                fullResponse += content;
                setCurrentResponse(prev => prev + content);
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }

      setChatHistory(prev => [
        ...prev,
        { role: 'assistant', content: fullResponse }
      ]);

      // Clear the interval once the first response is received
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }

    } catch (error) {
      console.error('Error fetching mood AI response:', error);
    }
  };

  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
    }
  }, [chatHistory, currentResponse]);

  useEffect(() => {
    setChatHistory([]);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedChatgptModel', selectedChatgptModel);
  }, [selectedChatgptModel]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory, currentResponse]);

  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = 'en-US';

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setPrompt(transcript);
        setIsListening(false);

        // Automatically submit the message after speech recognition ends
        if (transcript.trim()) { // Ensure there's a valid transcript
          const fakeEvent = { preventDefault: () => {} }; // Create a fake event object
          handleSubmit(fakeEvent);
        }
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error', event);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
        // Automatically submit the message if there's a valid prompt
        if (prompt.trim()) {
          const fakeEvent = { preventDefault: () => {} }; // Create a fake event object
          handleSubmit(fakeEvent);
        }
      };

      recognitionRef.current = recognition;
    } else {
      console.warn('Speech recognition not supported in this browser.');
    }
  }, []);


  const handleSubmit = async (e, reactionPrompt = null) => {
    let userPrompt = reactionPrompt || (prompt.trim() ? prompt : '');

    if (userPrompt === '') {
      console.log("Empty prompt, returning");
      return;
    }
    playSound('/assets/sounds/game-1.wav'); // Play sound before AI response
    e.preventDefault();
    setIsLoading(true);
    setCurrentResponse('');
    setIsResponseComplete(false);

    // Add user message to chat history immediately
    setChatHistory(prev => [...prev, { role: 'user', content: userPrompt }]);

    // Clear the chat input
    setPrompt('');

    // Start typing sound
    typingSound.loop = true;
    typingSound.play().catch(error => console.error('Error playing typing sound:', error));

    const messages = [
      {
        role: 'system',
        content: `${instructionprompt} Respond in a ${mood} manner. Here is some context from a document: ${pdfText}`
      },
      ...chatHistory,
      { role: 'user', content: userPrompt }
    ];

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: selectedChatgptModel,
          messages: messages,
          stream: true
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonString = line.slice(6);
            if (jsonString === '[DONE]') continue;

            try {
              const jsonData = JSON.parse(jsonString);
              const content = jsonData.choices[0]?.delta?.content || '';
              if (content) {
                fullResponse += content;
                setCurrentResponse(prev => prev + content);
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }

      // Determine an emoji based on the response content
      const aiEmoji = determineEmoji(fullResponse);

      setChatHistory(prev => [
        ...prev,
        { role: 'assistant', content: fullResponse, emoji: aiEmoji }
      ]);

      setIsResponseComplete(true);

    } catch (error) {
      console.error('Error:', error);
      setResponse('Error fetching response. Please check the API.');
    } finally {
      setIsLoading(false);
      typingSound.pause();
      typingSound.currentTime = 0;
    }
  };

  // Function to determine an emoji based on the response content
  const determineEmoji = (response) => {
    if (response.includes('happy')) return '😊';
    if (response.includes('sad')) return '😢';
    if (response.includes('love')) return '😍';
    if (response.includes('angry')) return '😠';
    if (response.includes('excited')) return '😃';
    if (response.includes('surprised')) return '😲';
    if (response.includes('disappointed')) return '😞';
    if (response.includes('grateful')) return '😇';
    if (response.includes('anxious')) return '😰';
    if (response.includes('content')) return '😌';
    if (response.includes('excited')) return '😃';
    if (response.includes('surprised')) return '😲';
    if (response.includes('disappointed')) return '😞';
    if (response.includes('grateful')) return '😇';
    if (response.includes('anxious')) return '😰';
    // Add more conditions as needed
    return '';
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const clearChat = () => {
    setChatHistory([]);
    setResponse('');
    setPrompt('');
    setPdfText('');

    // Clear the file input
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const playSound = (sound) => {
    const audio = new Audio(sound);
    audio.play();
  };

  const sendMoodMessage = async (selectedMood, userPrompt = '') => {
    const moodPrompt = `Respond with a message in a ${selectedMood} mood.`;
    const apiEndpoint = 'https://api.openai.com/v1/chat/completions';
    const headers = {
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json'
    };

    const data = {
      model: selectedChatgptModel,
      messages: [
        {
          role: 'system',
          content: `${instructionprompt} Respond with a message in a ${selectedMood} mood.`
        },
        { role: 'user', content: userPrompt || moodPrompt }
      ],
      stream: true
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsResponseComplete(false);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';

      playSound('/assets/sounds/game-1.wav'); // Play sound before AI response

      // Start typing sound
      typingSound.loop = true;
      typingSound.play().catch(error => console.error('Error playing typing sound:', error));

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonString = line.slice(6);
            if (jsonString === '[DONE]') continue;

            try {
              const jsonData = JSON.parse(jsonString);
              const content = jsonData.choices[0]?.delta?.content || '';
              if (content) {
                fullResponse += content;
                console.log('Streaming content:', content); // Debugging log
                setCurrentResponse(prev => prev + content); // Stream the response
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }

      setChatHistory(prev => [
        ...prev,
        { role: 'assistant', content: fullResponse }
      ]);

      setIsResponseComplete(true);

    } catch (error) {
      console.error('Error fetching mood AI response:', error);
    } finally {
      // Stop typing sound
      typingSound.pause();
      typingSound.currentTime = 0;
    }
  };

  const changeMood = (newMood) => {
    setMood(newMood);
    setCurrentResponse(''); // Clear the current response

    // Find the last assistant message
    const lastAssistantIndex = chatHistory.slice().reverse().findIndex(message => message.role === 'assistant');
    if (lastAssistantIndex !== -1) {
      // Calculate the correct index from the start of the array
      const indexToUpdate = chatHistory.length - 1 - lastAssistantIndex;

      // Remove the last assistant message
      setChatHistory(prev => {
        const updatedHistory = [...prev];
        updatedHistory.splice(indexToUpdate, 1);
        return updatedHistory;
      });
    }

    // Find the last user message to use as context for the new mood
    const lastUserMessage = chatHistory.slice().reverse().find(message => message.role === 'user');
    if (lastUserMessage) {
      sendMoodMessage(newMood, lastUserMessage.content); // Pass the last user message content
    }
  };

  const MAX_TEXT_LENGTH = 25000; // Set a maximum length for the text

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadMessage('');

      let text = '';
      if (file.type === 'application/pdf') {
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await getDocument({ data: arrayBuffer }).promise;
        for (let i = 0; i < pdf.numPages; i++) {
          const page = await pdf.getPage(i + 1);
          const content = await page.getTextContent();
          text += content.items.map(item => item.str).join(' ');
        }
      } else if (file.type === 'text/plain') {
        text = await file.text();
      } else {
        console.error('Unsupported file type');
        setUploadMessage('Unsupported file type');
        return;
      }

      // Truncate the text if it exceeds the maximum length
      if (text.length > MAX_TEXT_LENGTH) {
        text = text.substring(0, MAX_TEXT_LENGTH);
        setUploadMessage(`Uploaded file is too large. Only the first ${MAX_TEXT_LENGTH} characters are used.`);
      } else {
        setUploadMessage(`Uploaded ${file.type === 'application/pdf' ? 'PDF' : 'Text File'}: ${file.name}`);
      }

      setPdfText(text);
    }
  };

  const handleVoiceInput = () => {
    if (recognitionRef.current) {
      if (isListening) {
        recognitionRef.current.stop();
      } else {
        recognitionRef.current.start();
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setPrompt(suggestion);
    setIsSuggestionClicked(true);
    console.log('suggestion ', suggestion);
  };

  useEffect(() => {
    if (isSuggestionClicked) {
      const fakeEvent = { preventDefault: () => { } }; // Create a fake event object
      handleSubmit(fakeEvent);
      setIsSuggestionClicked(false); // Reset the flag
    }
  }, [prompt, isSuggestionClicked]); // Trigger handleSubmit when prompt changes due to suggestion click



  const synth = window.speechSynthesis;

  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);

  const moodVoiceMap = {
    calm: 'Google UK English Female',
    excited: 'Google US English Male',
    curious: 'Google UK English Male',
    thoughtful: 'Google US English Female',
    sad: 'Google UK English Female',
    depressed: 'Google US English Male',
  };

  const moodVoiceSettings = {
    calm: { pitch: 1.0, rate: 0.9 },
    excited: { pitch: 1.2, rate: 1.2 },
    curious: { pitch: 1.1, rate: 1.0 },
    thoughtful: { pitch: 0.9, rate: 0.8 },
    sad: { pitch: 0.8, rate: 0.7 },
    depressed: { pitch: 0.7, rate: 0.6 },
  };

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = synth.getVoices();
      setVoices(availableVoices);

      // Select voice based on current mood
      const preferredVoiceName = moodVoiceMap[mood];
      const defaultVoice = availableVoices.find(voice => voice.name.includes(preferredVoiceName)) || availableVoices[0];
      setSelectedVoice(defaultVoice);
    };

    loadVoices();
    synth.onvoiceschanged = loadVoices;
  }, [mood]); // Re-run effect when mood changes

  const [isPaused, setIsPaused] = useState(true);
  const [playQueue, setPlayQueue] = useState([]); // Queue for messages to be played
  const [wasPlaying, setWasPlaying] = useState(false); // Track if playback was active

  const [isAutoplay, setIsAutoplay] = useState(false); // New state for autoplay

  const [isSpeaking, setIsSpeaking] = useState(false);

  const speakText = (text) => {
    if (!text || !selectedVoice || synth.speaking) {
      console.warn('No text or voice selected, or already speaking');
      return;
    }

    const utterThis = new SpeechSynthesisUtterance(text);
    utterThis.voice = selectedVoice;

    // Apply mood-specific voice settings
    const { pitch, rate } = moodVoiceSettings[mood] || { pitch: 1.0, rate: 1.0 };
    utterThis.pitch = pitch;
    utterThis.rate = rate;

    utterThis.onstart = () => {
      setIsSpeaking(true); // Set speaking state to true
    };

    utterThis.onend = () => {
      console.log('Speech ended');
      setIsSpeaking(false); // Set speaking state to false
      setIsPaused(true); // Ensure the button state is updated
      typingSound.pause(); // Stop typing sound
      typingSound.currentTime = 0; // Reset typing sound

      // Trigger the next random message if enabled
      if (randomMessagesEnabled) {
        playMoodMessage(true);
      }
    };

    utterThis.onerror = (event) => {
      console.error('SpeechSynthesisUtterance.onerror', event);
      setIsSpeaking(false); // Set speaking state to false
      setIsPaused(true); // Ensure the button state is updated
      typingSound.pause(); // Stop typing sound
      typingSound.currentTime = 0; // Reset typing sound
    };

    console.log('Speaking:', text);
    synth.speak(utterThis);
  };

  const toggleVoicePlayback = () => {
    if (synth.speaking || synth.paused) {
      console.log('Stopping speech');
      synth.cancel(); // Stop the current speech
      setIsPaused(true);
      setIsAutoplay(false); // Disable autoplay when manually stopped
    } else if (selectedVoice && selectedVoice.name !== 'No Voice') {
      console.log('Starting speech');
      // Speak the last assistant message
      const lastAssistantMessage = chatHistory.slice().reverse().find(message => message.role === 'assistant');
      if (lastAssistantMessage) {
        speakText(lastAssistantMessage.content);
      }
      setIsPaused(false);
    }
  };

  const toggleAutoplay = () => {
    setIsAutoplay(prev => {
      const newAutoplayState = !prev;
      if (newAutoplayState) {
        setShowMessageBox(true);
      }
      return newAutoplayState;
    });
    console.log(`Autoplay is now ${!isAutoplay ? 'enabled' : 'disabled'}`);
  };

  // Autoplay effect
  useEffect(() => {
    if (isAutoplay && !synth.speaking) {
      console.log('Autoplaying speech');
      // Speak the last assistant message
      const lastAssistantMessage = chatHistory.slice().reverse().find(message => message.role === 'assistant');
      if (lastAssistantMessage) {
        speakText(lastAssistantMessage.content);
      }
    }
  }, [chatHistory, isAutoplay]); // Trigger when chatHistory or isAutoplay changes

  const [showMessageBox, setShowMessageBox] = useState(false);

  const [reactions, setReactions] = useState({}); // State to store reactions for each message

  const startNewChat = () => {
    setChatHistory([]); // Clear chat history
    setReactions({}); // Clear reactions
    setPrompt(''); // Clear prompt
    setResponse(''); // Clear response
    setCurrentResponse(''); // Clear current response
    setPdfText(''); // Clear the document text
    setUploadMessage(''); // Clear upload message
    setSuggestions([]); // Clear suggestions

    // Clear the file input
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleEmojiClick = (messageIndex, emoji) => {
    setReactions(prevReactions => ({
      ...prevReactions,
      [messageIndex]: emoji
    }));

    // Trigger AI response based on the emoji
    sendEmojiReactionToAI(emoji);
  };

  const sendEmojiReactionToAI = async (emoji) => {
    const reactionPrompt = `Respond to the user's reaction to your previous message: ${emoji}`;
    setCurrentResponse(''); // Reset current response for new streaming
    setIsResponseComplete(false); // Reset response completion state

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: selectedChatgptModel,
          messages: [
            {
              role: 'system',
              content: `${instructionprompt} Respond in a ${mood} manner.`
            },
            { role: 'user', content: reactionPrompt }
          ],
          stream: true
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';

      // Play sound before AI response
      playSound('/assets/sounds/game-1.wav');

      // Start typing sound
      typingSound.loop = true;
      typingSound.play().catch(error => console.error('Error playing typing sound:', error));

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonString = line.slice(6);
            if (jsonString === '[DONE]') continue;

            try {
              const jsonData = JSON.parse(jsonString);
              const content = jsonData.choices[0]?.delta?.content || '';
              if (content) {
                fullResponse += content;
                setCurrentResponse(prev => prev + content); // Stream the response
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }

      // Add the AI's response to the chat history
      setChatHistory(prev => [
        ...prev,
        { role: 'assistant', content: fullResponse }
      ]);

      setIsResponseComplete(true); // Mark response as complete

    } catch (error) {
      console.error('Error fetching AI response for emoji reaction:', error);
    } finally {
      // Stop typing sound
      typingSound.pause();
      typingSound.currentTime = 0;
    }
  };

  const emojiList = ['😀', '😂', '😍', '👎', '👍', ''];

  useEffect(() => {
    setReactions({}); // Clear reactions on component mount
  }, []);



  const getRandomInterval = () => Math.floor(Math.random() * (40000 - 25000 + 1)) + 25000;

  const handleToggleRandomMessages = () => {
    setRandomMessagesEnabled(prevState => {
      const newState = !prevState;
      console.log("randomMessagesEnabled is now:", newState);

      if (newState) {
        const interval = getRandomInterval();
        setProgress(0);
        setTimeRemaining(interval / 1000);
        scheduleNextMessage(interval);
      } else {
        clearInterval(intervalId);
        setIntervalId(null);
      }

      return newState;
    });
  };

  const scheduleNextMessage = (interval) => {
    setProgress(0);
    const id = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(id);
          playMoodMessage(true);
          return 0;
        }
        return prev + (100 / (interval / 1000));
      });
      setTimeRemaining(prev => prev - 1); // Decrease time remaining
    }, 1000);
    setIntervalId(id);
  };

  useEffect(() => {
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [intervalId]);

  const [isLoadingAI, setIsLoadingAI] = useState(false); // State to track AI loading

  useEffect(() => {
    const fetchInitialGreeting = async () => {
      console.log("Fetching initial greeting..."); // Debugging log
      const greetingPrompt = "Please provide a friendly greeting.";
      const apiEndpoint = 'https://api.openai.com/v1/chat/completions';
      const headers = {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      };

      const data = {
        model: selectedChatgptModel,
        messages: [
          {
            role: 'system',
            content: `${instructionprompt} Respond with a friendly greeting.`
          },
          { role: 'user', content: greetingPrompt }
        ],
        stream: true
      };

      try {
        setIsLoadingAI(true); // Start loading
        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let fullResponse = '';

        // Start typing sound
        typingSound.loop = true;
        typingSound.play().catch(error => console.error('Error playing typing sound:', error));

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const jsonString = line.slice(6);
              if (jsonString === '[DONE]') continue;

              try {
                const jsonData = JSON.parse(jsonString);
                const content = jsonData.choices[0]?.delta?.content || '';
                if (content) {
                  fullResponse += content;
                  setCurrentResponse(prev => prev + content); // Stream the response
                }
              } catch (e) {
                console.error('Error parsing JSON:', e);
              }
            }
          }
        }

        // Add the full response to the chat history once
        setChatHistory(prev => [
          ...prev,
          { role: 'assistant', content: fullResponse }
        ]);

        // Clear currentResponse after adding to history
        setCurrentResponse('');

      } catch (error) {
        console.error('Error fetching initial greeting:', error);
      } finally {
        // Stop typing sound
        typingSound.pause();
        typingSound.currentTime = 0;
        setIsLoadingAI(false); // End loading
      }
    };

    fetchInitialGreeting();
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className="app-container">
      <div className="chat-container">

        {mood === 'depressed' && (
          <div className="rain-container">
            {[...Array(20)].map((_, i) => (
              <div key={i} className="raindrop"></div>
            ))}
          </div>
        )}
        <div className={`mood-indicator ${mood}`}></div>
        <h1 className={`title ${mood}`}>
          pAIter
          <span className="roboticon shake glow">
            {mood === 'calm' && (
              <i className="fas fa-robot"></i>
            )}
            {mood === 'excited' && (
              <i className="fas fa-grin-stars"></i>
            )}
            {mood === 'curious' && (
              <i className="fas fa-question"></i>
            )}
            {mood === 'thoughtful' && (
              <i className="fas fa-lightbulb"></i>
            )}
            {mood === 'sad' && (
              <i className="fas fa-sad-tear"></i>
            )}
            {mood === 'depressed' && (
              <i className="fas fa-frown"></i>
            )}
            {!['calm', 'excited', 'curious', 'thoughtful', 'sad', 'depressed'].includes(mood) && (
              <i className="fas fa-robot"></i>
            )}
          </span>
        </h1>

        <div className="mood-buttons">
          <Tippy content="Calm">
            <button className={`calm ${mood === 'calm' ? 'selected' : ''}`} onClick={() => changeMood('calm')}>
              <i className="fas fa-smile"></i>
            </button>
          </Tippy>
          <Tippy content="Excited">
            <button className={`excited ${mood === 'excited' ? 'selected' : ''}`} onClick={() => changeMood('excited')}>
              <i className="fas fa-grin-stars"></i>
            </button>
          </Tippy>
          <Tippy content="Curious">
            <button className={`curious ${mood === 'curious' ? 'selected' : ''}`} onClick={() => changeMood('curious')}>
              <i className="fas fa-question"></i>
            </button>
          </Tippy>
          <Tippy content="Thoughtful">
            <button className={`thoughtful ${mood === 'thoughtful' ? 'selected' : ''}`} onClick={() => changeMood('thoughtful')}>
              <i className="fas fa-lightbulb"></i>
            </button>
          </Tippy>
          <Tippy content="Sad">
            <button className={`sad ${mood === 'sad' ? 'selected' : ''}`} onClick={() => changeMood('sad')}>
              <i className="fas fa-sad-tear"></i>
            </button>
          </Tippy>
          <Tippy content="Depressed">
            <button className={`depressed ${mood === 'depressed' ? 'selected' : ''}`} onClick={() => changeMood('depressed')}>
              <i className="fas fa-frown"></i>
            </button>
          </Tippy>
        </div>

        <div className="top-button-group">
          {chatHistory.length > 0 && (
            <button onClick={startNewChat} className={`button new-chat-button ${mood}`}>
              <i className="fas fa-plus"></i>
            </button>
          )}
          {isLoadingAI && (
            <div className="loading-indicator">
              <i className="fas fa-spinner fa-spin"></i> Hang on a sec...
            </div>
          )}
        </div>


        {uploadMessage && (
          <div className="upload-notification">
            {uploadMessage}
          </div>
        )}


        {uploadMessage && suggestions.length > 0 && (
          <div className="suggestions-container">
            <h3 className={`suggestions-title ${mood}`}>Suggestions:</h3>
            <ul>
              {suggestions.map((suggestion, index) => (
                <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion}
                </li>
              ))}
            </ul>
          </div>
        )}


        <div className="response-container " ref={scrollableContainerRef}>
          {chatHistory.map((message, index) => (
            <div key={index} className={`response-content ${message.role}`}>
              {message.role === 'assistant' && <i className="fas fa-robot" style={{ marginRight: '8px' }}></i>}
              <ReactMarkdown
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                      <SyntaxHighlighter
                        style={vscDarkPlus}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      >
                        {String(children).replace(/\n$/, '')}
                      </SyntaxHighlighter>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  }
                }}
              >
                {message.content}
              </ReactMarkdown>
              {message.emoji && <div className="selected-reaction">{message.emoji}</div>}
              {message.role === 'assistant' && (
                <div className="emoji-reactions">
                  {emojiList.map((emoji, emojiIndex) => (
                    <span
                      key={emojiIndex}
                      className="emoji"
                      onClick={() => handleEmojiClick(index, emoji)}
                    >
                      {emoji}
                    </span>
                  ))}
                </div>
              )}
              {reactions[index] && <div className="selected-reaction">{reactions[index]}</div>}
            </div>
          ))}

          {!isResponseComplete && currentResponse && (
            <div className="response-content assistant">
              <i className="fas fa-robot" style={{ marginRight: '8px' }}></i>
              <ReactMarkdown
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                      <SyntaxHighlighter
                        style={vscDarkPlus}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      >
                        {String(children).replace(/\n$/, '')}
                      </SyntaxHighlighter>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  }
                }}
              >
                {currentResponse}
              </ReactMarkdown>
            </div>
          )}
          <div ref={chatEndRef} />
        </div>

        <form onSubmit={handleSubmit} className="chat-form">
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={handleKeyDown}
            className={`chat-input ${mood}`}
            placeholder={uploadMessage ? 'Ask pAIter questions about the document' : 'Talk to me, upload a document and ask me anything about it in any mood...'}
          />
          <div className="button-group">
            <button
              type="button"
              onClick={handleVoiceInput}
              className={`button voice-input-button ${mood}`}
            >
              {isListening ? <><i className="fas fa-stop"></i> </> : <><i className="fas fa-microphone"></i></>}
            </button>
            {currentResponse && currentResponse.trim() && (
              <>

                <Tippy content="Play/Stop voice">
                  <button
                    type="button"
                    onClick={toggleVoicePlayback}
                    className={`button play-button ${mood} ${isAutoplay ? 'playing' : ''}`}
                  >
                    <i className={`fas ${isAutoplay ? 'fa-stop' : 'fa-play'}`}></i>
                  </button>
                </Tippy>
                <Tippy content="Toggle automatic voice playback">
                  <button
                    type="button"
                    onClick={toggleAutoplay}
                    className={`button autoplay-button ${mood} ${isAutoplay ? 'active' : ''}`}
                  >
                    <i className={`fas ${isAutoplay ? 'fa-toggle-on' : 'fa-toggle-off'}`}></i>
                  </button>
                </Tippy>
              </>
            )}
            <Tippy content="Upload PDF or text file">
              <label htmlFor="file-input" className={`button file-input-label ${mood}`}>
                <i className="fas fa-file-upload"></i>
              </label>
            </Tippy>
            <input id="file-input" type="file" accept="application/pdf,text/plain" onChange={handleFileChange} className="file-input" />

            <div className="right-buttons">
              <button
                type="submit"
                className={`button submit-button ${mood}`}
                disabled={isLoading || !prompt.trim()}
              >
                {isLoading ?
                  <><i className="fas fa-spinner fa-spin"></i> Thinking...</> :
                  <><i className="fas fa-paper-plane"></i> Chat</>
                }
              </button>
            </div>
          </div>
        </form>

        {showMessageBox && (
          <div className="message-box">
            <p>This autoplay feature is a bit buggy</p>
            <button onClick={() => setShowMessageBox(false)}>Close</button>
          </div>
        )}

        {chatHistory.length > 0 && (
          <Tippy content="Enable Random AI Conversational Messages">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={randomMessagesEnabled}
                onChange={handleToggleRandomMessages}
              />
              <span className="slider"></span>
            </label>
          </Tippy>
        )}

        {randomMessagesEnabled && (
          <div className="progress-bar">
            <Tippy content={`Next random message in ${Math.floor(timeRemaining / 60)}:${Math.floor(timeRemaining % 60).toString().padStart(2, '0')}`}>
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </Tippy>
          </div>
        )}

        {isSpeaking && (
          <div className="music-bar">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        )}

      </div>
    </div>
  );
}

export default Paiter;
